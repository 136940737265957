
import { Module,VuexModule,getModule,Mutation,Action } from "vuex-module-decorators"
import store from "@/store/index"
import AddNoticeAdminStoreType from "@/views/PlatformAdmin/AddNoticeAdmin/Store/indexType";

@Module({
    name:"AddNoticeAdmin",
    store,
    dynamic: true,
})
export default class AddNoticeAdmin extends VuexModule implements AddNoticeAdminStoreType{
    AddNoticeAdminImg: any[] = [];
    get getAddNoticeAdminImg(){
        return this.AddNoticeAdminImg
    }
    @Mutation
    SetAddNoticeAdminImg(data: any[]): void {
        this.AddNoticeAdminImg = data
    }

    AddNoticeAdminVideo: any[] = [];
    get getAddNoticeAdminVideo(){
        return this.AddNoticeAdminVideo
    }
    @Mutation
    SetAddNoticeAdminVideo(data: any[]): void {
        this.AddNoticeAdminVideo = data
    }

}

export const AddNoticeAdminStore = getModule( AddNoticeAdmin )
