
import { UpPhotos } from "@/Api/UpPhoto";
import {Dialog, Toast} from "vant";
import Storage from "@/util/Storage";
import {AddNoticeAdminStore} from "@/views/PlatformAdmin/AddNoticeAdmin/Store";
import {filterUrl} from "@/views/PlatformAdmin/AddNoticeAdmin/Util";
import {keyData} from "@/views/PlatformAdmin/AddNoticeAdmin/Model";
import {GetNoticeSuc} from "@/Api/Basics";
import {issueNoticeAdmin, updateNoticeAdmin} from "@/Api/Platform/bascs";

// 上传图片
export function upLoadImage(imgArr = []) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    const fromData = new FormData()
    fromData.append("functionName","other")
    imgArr.forEach(item=>fromData.append("files",item.file, item.file.name))
    return (
        new Promise((resolve, reject) => {
            if ( imgArr.length > 2 ){
                Dialog({ title:"温馨提示",message:"上传图片数量大于2张，上传速度较慢，是否继续？" }).then(res=>resolve()).catch(cat=>reject())
            }else{
                resolve()
            }
        }).then(res=>{
            Toast.loading({ duration:0,message:"上传中~~~" })
            return Promise.resolve(UpPhotos({ userId,token },fromData).then(res=>{
                Toast.clear()
                if ( res.data ){
                    let img = AddNoticeAdminStore.getAddNoticeAdminImg;
                    AddNoticeAdminStore.SetAddNoticeAdminImg(img.concat(res.data || []))
                }
                return res.data || []
            }))
        }).catch(cat=>{
            Toast.clear()
        })
    )
}


// 上传视频
export function upLoadVideo(videoArr = []) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    const fromData = new FormData();
    fromData.append("functionName","video")
    videoArr.forEach(item=>fromData.append("files",item.file, item.file.name))
    return (
        new Promise((resolve, reject) => {
            if (videoArr.length > 1) {
                Dialog({ title:"温馨提示",message:"上传视频数量大于2张，上传速度较慢，是否继续？" }).then(res=>resolve()).catch(cat=>reject())
            }else{
                resolve()
            }
        }).then(res=>{
            Toast.loading({ duration:0,message:"上传中~~~" })
            return Promise.resolve(UpPhotos({ userId,token },fromData).then(res=>{
                Toast.clear()
                if ( res.data ){
                    let img = AddNoticeAdminStore.getAddNoticeAdminVideo;
                    AddNoticeAdminStore.SetAddNoticeAdminVideo(img.concat(res.data || []))
                }
                return res.data || []
            }))
        }).catch(cat=>{
            Toast.clear()
        })
    )
}

// 详情
export function getNoticeSuc(id:string|number = "") {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    return Promise.resolve(GetNoticeSuc({ userId,token },{ id }).then(res=>{
        return res.data
    }))
}

// 添加
export function addNotice(body :any= {}) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    let imgArr = AddNoticeAdminStore.getAddNoticeAdminImg,
        videoArr = AddNoticeAdminStore.getAddNoticeAdminVideo,
        imgStr = "",videoStr = "",num = 0;
    keyData.forEach(item=>{
        if ( !body[item] ) num ++;
    })
    if ( num ){
        Toast("请输入公告内容")
        return Promise.reject()
    }
    if ( imgArr.length ){
        imgStr = filterUrl(imgArr);
        body.image = imgStr;
    }
    if ( videoArr.length ){
        videoStr = filterUrl(videoArr);
        body.video = videoStr
    }
    Toast.loading({ duration:0,message:"添加中~~" })
    return Promise.resolve(issueNoticeAdmin({userId,token},body).then(res=>{
        Toast.clear()
        return res.data
    }))
}


// 修改
export function updateNotice(body:any = {},oldData:any = {}) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    let imgArr = AddNoticeAdminStore.getAddNoticeAdminImg,
        videoArr = AddNoticeAdminStore.getAddNoticeAdminVideo,
        imgStr = "",videoStr = "",num = 0;
    keyData.forEach(item=>{
        if ( body[item] ) {
            if ( body[item] != oldData[item] ){
                num ++;
            }
        }
    })
    if ( imgArr.length ){
        imgStr = filterUrl(imgArr);
        body.image = imgStr;
    }
    if ( videoArr.length ){
        videoStr = filterUrl(videoArr);
        body.video = videoStr
    }
    if ( body["image"] != oldData["image"] ) num ++;
    if ( body["video"] != oldData["video"] ) num ++;
    body["id"] = oldData["id"]

    if ( num <= 0 ){
        Toast("请修改公告内容")
        return Promise.reject()
    }
    Toast.loading({ duration:0,message:"修改中~~~" })
    return Promise.resolve(updateNoticeAdmin({userId,token},body).then(res=>{
        Toast.clear()
        return res.data
    }))
}
