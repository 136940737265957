

export const keyData = [
    "title",
    "content",
    "dateStr",
]

export const HideData = {
    "title":"请输入标题",
    "content":"请输入公告内容",
    "dateStr":"请输入生效时间",
}
