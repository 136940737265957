

// 过滤 url 列表
export function filterUrl(list = []):string {
    let urlStr = "";
    list.forEach((item,index)=>{
        if (index){
            urlStr += (","+item)
        }else{
            urlStr = item
        }
    })
    return urlStr;
}
